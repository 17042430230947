import React, { useRef } from "react";
import { useFrame } from "@react-three/fiber";
import { useControls } from "leva";

function Sphere3d(props) {
  // This reference gives us direct access to the THREE.Mesh object
  const ref = useRef();

  const { scale, rotationX, rotationY, rotationZ, wireframe, color } =
    useControls("Sphere /w Image", {
      scale: props.scale || 1,
      rotationX: 0.01,
      rotationY: 0.05,
      rotationZ: 0.03,
      wireframe: props.wireframe || false,
      color: props.color || "red",
    });
  useFrame((state, delta) => (ref.current.rotation.x += rotationX));
  useFrame((state, delta) => (ref.current.rotation.y += rotationY));
  useFrame((state, delta) => (ref.current.rotation.z += rotationZ));

  return (
    <mesh {...props} scale={scale} ref={ref}>
      <meshBasicMaterial attach="material" />
      <sphereGeometry args={[1, 16, 16]} />
      <meshStandardMaterial wireframe={wireframe} color={color} />
    </mesh>
  );
}

export default Sphere3d;
