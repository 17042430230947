import { ReactNode } from "react";

export default function ConditionalComponent({
  condition,
  children,
}: {
  condition: boolean;
  children: ReactNode;
}) {
  return condition ? <>{children}</> : null;
}
