import { Canvas } from "@react-three/fiber";
import "./resizeObserver.polyfill";
import Cylinder3d from "./components/Shapes/Cylinder3d";
import PodvalRound from "./assets/logos/podvalRound.png";
import ViN5 from "./assets/graphics/ViN5pentagram 1.png";
import {
  Bloom,
  DepthOfField,
  EffectComposer,
  Glitch,
  Noise,
} from "@react-three/postprocessing";
import Sphere3d from "./components/Shapes/Sphere3d";
import { useControls } from "leva";
import ConditionalComponent from "./components/util/ConditionalComponent";
import React from "react";
import Circle3d from "./components/Shapes/Circle3d";

function App() {
  const { background, showCylinder, showSphere3d, showCircle3d } = useControls(
    "Main Config",
    {
      background: "#000000",
      showCylinder: true,
      showSphere3d: false,
      showCircle3d: false,
    }
  );
  const { showGlitch, showBloom, showDepthOfField, showNoise } = useControls(
    "FX",
    {
      showGlitch: false,
      showDepthOfField: true,
      showBloom: false,
      showNoise: false,
    }
  );

  return (
    <>
      <section
        style={{
          display: "flex",
          height: "100%",
          width: "100%",
          background: background,
        }}
      >
        <Canvas style={{ height: "100%", width: "100%" }}>
          <EffectComposer>
            <ConditionalComponent condition={showDepthOfField}>
              <DepthOfField
                focusDistance={0}
                focalLength={0.02}
                bokehScale={2}
                height={480}
              />
            </ConditionalComponent>
            <ConditionalComponent condition={showGlitch}>
              <Glitch />
            </ConditionalComponent>
            <ConditionalComponent condition={showBloom}>
              <Bloom
                luminanceThreshold={0}
                luminanceSmoothing={0.9}
                height={300}
              />
            </ConditionalComponent>
            <ConditionalComponent condition={showNoise}>
              <Noise opacity={0.4} />
            </ConditionalComponent>
            <pointLight position={[10, 10, 10]} />
            <ambientLight intensity={0.1} color={"red"} />

            <ConditionalComponent condition={showSphere3d}>
              <Sphere3d position={[0, 0, 0]} wireframe={true} scale={2} />
            </ConditionalComponent>
            <ConditionalComponent condition={showCircle3d}>
              <Circle3d position={[0, 0, 0]} wireframe={true} texture={ViN5} />
            </ConditionalComponent>

            <ConditionalComponent condition={showCylinder}>
              <Cylinder3d
                position={[0, 0, 0]}
                wireframe={false}
                scale={1}
                texture={PodvalRound}
              />
            </ConditionalComponent>
          </EffectComposer>
        </Canvas>
      </section>
    </>
  );
}

export default App;
