import React, { useRef, useState } from "react";
import { useFrame, useLoader } from "@react-three/fiber";
import { TextureLoader } from "three";
import { useControls } from "leva";

function Cylinder3d(props) {
  const ref = useRef();
  const [clicked, click] = useState(false);

  const { scale, texture, rotationX, rotationY, rotationZ, wireframe, color } =
    useControls("Cylinder", {
      scale: 1,
      texture: props.texture || "https://i.imgur.com/3f2iZ0o.jpeg",
      rotationX: 0.01,
      rotationY: 0.01,
      rotationZ: 0.0,
      wireframe: props.wireframe || false,
      color: props.color || "white",
    });
  // Subscribe this component to the render-loop, rotate the mesh every frame
  useFrame((state, delta) => (ref.current.rotation.x += rotationX));
  useFrame((state, delta) => (ref.current.rotation.y += rotationY));
  useFrame((state, delta) => (ref.current.rotation.z += rotationZ));
  // Return the view, these are regular Threejs elements expressed in JSX
  const textureLoader = useLoader(TextureLoader, texture);
  return (
    <mesh
      {...props}
      ref={ref}
      scale={scale}
      wireframe={wireframe}
      onClick={(event) => click(!clicked)}
      onPointerOver={(event) => null}
      onPointerOut={(event) => null}
    >
      <cylinderGeometry args={[1, 1, 0.1]} />
      <meshStandardMaterial
        map={textureLoader}
        castShadow={true}
        wireframe={wireframe}
        color={color}
      />
    </mesh>
  );
}

export default Cylinder3d;
