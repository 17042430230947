import React, { useRef } from "react";
import { useFrame, useLoader } from "@react-three/fiber";
import { TextureLoader } from "three";
import { useControls } from "leva";

function Circle3d(props) {
  const { scale, texture, rotationX, rotationY, rotationZ, wireframe } =
    useControls("Cyrcle /w Image", {
      scale: 1,
      texture: props.texture || "https://i.imgur.com/3f2iZ0o.jpeg",
      rotationX: 0.0,
      rotationY: 0.0,
      rotationZ: 0.01,
      wireframe: props.wireframe || false,
    });
  const textureLoader = useLoader(TextureLoader, texture);

  const ref = useRef();
  useFrame((state, delta) => (ref.current.rotation.x += rotationX));
  useFrame((state, delta) => (ref.current.rotation.y += rotationY));
  useFrame((state, delta) => (ref.current.rotation.z += rotationZ));

  return (
    <mesh {...props} wireframe={wireframe} scale={scale} ref={ref}>
      <circleGeometry />
      <meshStandardMaterial wireframe={wireframe} map={textureLoader} />
    </mesh>
  );
}

export default Circle3d;
